body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
  background-color: #f2f2f2;
}

.login_img {
  height: 70vh;
  width: auto;
}
.width-40{
  max-width: 20%;
  width:100%;

}
.login_img_box {
  background: #2DA2FB;
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_form_box {
  /* margin:auto; */
  margin: 50px auto;
}
.signup_form_box {
  /* margin:auto; */
  /* margin:50px auto; */
  margin: 5px auto;
}


.lilita{
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* dasboard */

.dashboard_main {
  max-width: 100%;
  width: 100%;
   height: 100%;
  margin:0;
  display: flex;
  justify-content: flex-start;
  background-color: #f2f2f2;

}


/* sidebar */
.dashboard_main .dashboard_sidebar{
  max-width: 10vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 15px 10px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.dashboard_main .dashboard_sidebar .dashboard_sidebar_menu_item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  padding:10px 0;

}
.dashboard_main .dashboard_sidebar .navLink {
  text-decoration: none;
  color:black;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: auto;
  border-radius: 10px;
  width: 90px;
  height: 75px;
  margin: 0px auto 0 auto;
  background-color: #2DA2FB;

}
.navLink {
  text-decoration: none;
  color:black;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: auto;
  border-radius: 10px;
  width: 90px;
  height: 75px;
  margin: 0px auto 0 auto;
  background-color: #2DA2FB;

}
.navLink {
  text-decoration: none;
  color:black;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: auto;
  border-radius: 10px;
  width: 90px;
  height: 75px;
  margin: 70px auto 0 auto;
  background-color: #2DA2FB;
}
.mt-10{
  margin-top: 10px;
}
.mt-5px{
  margin-top: 5px;
}
.margin-left-300px{
  margin-left: 300px;
}
.hoverBTN {
  color:white;
  background-color: #434343;
  transition: 0.3s ease;
  display: inline;
  width: 12rem;
  margin-right: 12px;
  display: inline;
  width: 12rem;
  margin-right: 12px;
}
.hoverBTN:hover{
  color:white;
  background-color: #2DA2FB;
}






.dashboard_main .dashboard_sidebar .navLink.active {
  color:#fff;
  background-color: #2DA2FB;
  border-radius: 10px;
  width: 90px;
  height: 75px;
  margin: 40px auto 0 auto;
}


.dashboard_main .dashboard_sidebar .navLink:hover{
  color:#fff;
  background-color: #2DA2FB;
  border-radius: 10px;
}
._selected {
  background-color: #2DA2FB;
  color:#fff;
  display: flex;
  justify-content: start;
  align-items: center;
}
._selected p {
  margin:0;
  padding:0;

}
.dashboard_main .dashboard_sidebar .dashboard_sidebar_menu_item img {
  height: 35px;

}

.dashboard_main .dashboard_sidebar .dashboard_sidebar_menu_item p{
  font-size:14px;
  margin-top:8px;
}



/* top header */
.dashboard_main .top_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  height: 55px;
  background-color: white;
  border-radius: 10px;
  padding:0 10px;
  font-size: 14px;
}

.dashboard_main .top_header .welcome_text{
  display: flex;
  justify-content: center;
}
.dashboard_main .top_header .welcome_text h2{
  font-size:14px;
  margin-top: 1.3px;
  margin-right: 3px;

}
.dashboard_main .top_header .welcome_text p{
  font-size:14px;
  margin:0;
}
.dashboard_main .top_header .welcome_text img{
  height: 20px;
  margin-left:10px;
  margin-top: -4.5px;
}



.dashboard_main .top_header .install_plugin{
  display: flex;
  align-items: center;
  gap:20px;
  text-decoration: none;
   color:#2DA2FB;
   font-weight: 500;
   font-size: 14px;
}
.dashboard_main .top_header .install_plugin p{
  margin:0;
  font-size: 14px;
}
.dashboard_main .top_header .install_plugin img{
  height: 60px;
}
.dashboard_main .top_header .search_bar{
  background-color: #fff;
  border-radius: 10px;
  padding:2px;
  border:1px solid #2f2f2f;
  display: flex;
  display: flex;
}
.dashboard_main .top_header .search_bar img{
  height: 28px;
}
.dashboard_main .top_header .search_bar input {
  border:0;
  outline:0;

}
.dashboard_main .top_header .profile_icon img{
  height: 50px;
}

.table_cell{
  border: 1px solid black;
}




.tTable{
  border:1px solid black;
}

.tableTrail{
  width: auto ;
  display: flex;
  background-color: #fff;
  margin-right: 10px;
}
 .tableTrail th, .tableTrail td{
  border:1px solid #2f2f2f;
}

.line-height-2{
  line-height: 2 !important;
}

.f-500{
  font-weight: 500  !important;
}


.rm_link{
  text-decoration: none;
}


.divider {
  margin:50px auto;
}



.or-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:50px 0;
}

.or-text {
  padding: 0 10px;
  color: #333;
  font-weight: bold;
}

.line {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #333;
}



.untagged-container {
  position: relative;
}
button.updateButton{
  display: flex;
  display: flex;
  outline:none;
  border:none;
  color:white;
  padding:20px 40px;
  font-size:14px;
  background-color: #2DA2FB;
  margin:30px 10px;
  margin:30px 10px;
}
button.position-right {
  position: absolute;
  right:0;
  top:-80px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  width: 100px !important;
  border: none;
  font-size:14px;
}
.bg-blue{
  background-color:#2DA2FB;
}
.f-13{
  font-size: 14px;
}

/* main */
.dashboard_main .main_content{
  flex: 1 1;
  max-width: 88%;
  background-color: #f2f2f2;
  margin-left: 11%;
}

.tab_data {
  padding-left: 10px !important;
  height: 500px !important;
  overflow: scroll !important;
  margin-top: 30px !important;
}

.dashboard_main .main_content .tab_items{
  display:flex;
  gap:20px;
  margin-top:20px;

}

.dashboard_main .main_content .tab_items button{
  border:none;
  outline:none;
  cursor:pointer;
  font-weight: bold;
  padding:10px 20px;
}
.dashboard_main .main_content .tab_items  button.active{
  background-color: white;
}



.cc{
  display: flex;
  padding: 2px 0;
  display: grid;
  grid-template-columns: auto max-content;
}
.cc .prev_next{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:30px;
  height: 65px;
  margin: 0px 10px 0px 60px;

}
.cc .prev_next .nextBTN{
  background-color: #2DA2FB !important;
  width: 100px !important;
  border: none;

}
.cc .prev_next .previousBTN{
  background-color: #2DA2FB !important;
  width: 100px !important;
  border: none;

}
.main_row_btn{
  display: flex;
  justify-content: center;
  gap:7.2rem;
  align-items: center;
  margin:25px 60px;
  position: relative;
}
.main_row_btn::before{
  content:'';
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  height: 1px;
  background-color: black;
  width: 100%;
}
.password_div {
  position: relative;
}
.input_password {
  position: relative;
}
.view_eye_btn{
  border:none;
  outline:none;
  background:transparent;
  position:absolute;
  right: 0;
  top:10px;
  fill: rgba(0, 0, 0, 0.6);
}
.main_btn_row{
  display: flex;
    /* justify-content: space-between; */
    gap: 4%;
    align-items: center;
    margin: 0 40px;
    position: relative
}
.main_btn_row button{
  border:none;
  outline:none;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
}

.sub_header{
  background: white;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 15px;
}

.sub_h {
  background-color: #F2F2F2 !important;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2  ;
  padding-bottom: 1px;
}

.main_row_btn button{
  border:none;
  outline:none;
  background-color: #2f2f2f;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color:white;
  font-size:14px;
  transition: 0.3s ease;
  text-align: center;
  position: relative;
}

.main_row_btn button:hover {
  background-color: #2DA2FB;
}

.main_row_btn button.active{
  background-color: #2DA2FB;
}

.white_body{
  /* height:100vh !important; */
  position: relative;
  padding: 10px;
  background: white;
  border-radius: 10px;
  /* font-size: 14px !important; */
}

.input_box{
  display: flex;
  margin:10px auto;
 gap:50px;
 align-items: center;
}
.input_box #S_client{
  border:1px solid #2f2f2f;
  outline:none;
  background-color: white;
  height: 50px;
  border-radius: 10px;
  font-size:14px;
  text-align: center;
}

.input_box #S_fy{
  border:1px solid #2f2f2f;
  outline:none;
  background-color: white;
  height: 50px;
  border-radius: 10px;
  font-size:14px;
  text-align: center;
  padding:0 50px;
}

.formInput{
  border:1px solid #2f2f2f;
  outline:none;
  background-color: white;
  height: 40px;
  border-radius: 10px;
  font-size:14px;
  text-align: center;
  padding:0 50px;
  width: 100%;
}
.textArea{
  height: 100px;
  padding: 40px 0 0 0;
}


.folder_svg {
  height: 20px;
  width: 20px;
}

.select_box_head{
  min-width: 18em;

}







.cursor-pointer {
  cursor: pointer;
  transition: all 0.3s;
  height: 40px  !important;
  display: flex;
  justify-content: start;
  align-items: center;
}
.cursor-pointer p{
  margin:0;
  padding:0;
}
.w-30p {
  width: 30%;
}
.cursor-pointer:hover{
   background-color: #2DA2FB;
   color: #fff;
}

.flex_input{
  display: flex;
  gap:50px;
}
.client_list{
  margin:100px auto;
  font-size: 14px !important;
}
.show{
  display: block;
}
.hide{
  display: none;
}

.field_error{
  border:1px solid red !important;
  color:red !important;
}
/*
.sub_header{
  position: sticky;
  top:10%;
  background-color: #fff;
  padding:10px auto;

} */

.hamburger .dashboard_sidebar_nav .navLink {
  width: 5.5rem;
  margin-bottom: 15px;
}
.hamburger .dashboard_sidebar_nav .navLink {
  width: 5.5rem;
  margin-bottom: 15px;
}




.fa_register_table{
  font-size: 14px;
  border:1px solid black;
  width: 100%;
  margin:0 auto;
  padding:0 10px;
}


.fa_register_table thead{
  background-color: #2DA2FB;
  color: #fff;
  border: 1px solid black;
}
.fa_register_table  tr td {
  border:1px solid black;
  padding:0px 4px;
}

.border_gray{
  border:1px solid #f2f2f2;
}


.profile_icon button.btn.btn-danger {
  padding: 5px 12px;
  margin-left: 3px;
}


@media only screen and (max-width: 1200px) {

  .login_img {
    height: 40vh;
    width: auto;
  }
  .dashboard_main .dashboard_sidebar{
    min-width: 10vw;
  }
  .dashboard_main {
    padding: 0;
  }

.dashboard_main .top_header {
  margin: 15px 10px 15px 0px;
}


}



@media only screen and (min-width:900px) {
  .hamburger {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .login_img {
    height: 30vh;
    width: auto;
  }



  .dashboard_sidebar .navLink{
    display: none;
  }

  .hamburger {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .hamburger .dashboard_sidebar_nav .navLink {
    display: block;
  }



  .dashboard_sidebar .navLink{
    display: none;
  }

  .hamburger {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .hamburger .dashboard_sidebar_nav .navLink {
    display: block;
  }
}


@media only screen and (max-width: 768px) {

  .login_img,
  .login_img_box {
    display: none;
  }

  .login_form_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    /* margin: 5px auto; */
  }
  .signup_form_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 50px auto; */
    margin: 5px auto;
  }

  .dashboard_sidebar .navLink{
    display: none;
  }

  .hamburger .dashboard_sidebar_nav .navLink {
    display: block;
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

}

.MuiButton-contained {
  text-transform: capitalize;

}
.MuiButton-contained:hover {
  background-color: #2DA2FB;
  box-shadow: none;
}


.logout_btn {
  background-color: #dc3545;
  padding: auto;
  border-radius: 4px;
  color: white;
  text-transform: capitalize;
}
.logout_btn:hover {
  background-color: #e9384a;
  padding: auto;
  border-radius: 4px;
  color: white;
}


/* Add styles for the custom button */
.custom-button {
  background-color: #2DA2FB;
  color: white;
  padding: 10px 16px;
  /* border-radius: 4px; */
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  user-select: none;
}


.MuiTableHead-root{
  background-color: #808080;
}
.MuiTableCell-head {
  color: white;
}


.MuiButton-contained {
  background-color: #383b3f;
  box-shadow: none;
}

.btn_untagged{
  position: absolute;
  top:20%;
  left:40%;
}




.MuiTableCell-root .MuiInputBase-input{
  padding: 0px 2px 0px 6px ;
}


.MuiTableCell-root .MuiOutlinedInput-root  {
  padding: 0px !important;
}

.MuiTableCell-root .MuiAutocomplete-input{
  padding: 0px 2px 0px 6px !important ;
  width: 60% !important;
}

.other_notes_btn{
  display: flex;
  flex-direction: row;
  gap: 5vw;
  flex-wrap: wrap;
}

.other_notes_container{
  font-size: 14px;
}

.other_notes_btn .MuiButton-outlined{
  margin-left: 10px;
  text-transform:none;
  width:170px;
  font-weight: 500;
  color: black;
  border: 2px solid #f2f2f2;
  border-radius: 6px;
  padding: 5px ;
}

.lineBreak {
  background-color: #F2F2F2;
  height: 10px !important;
  z-index: 999;
  padding:0 !important;
  margin: 0 -20px;

}
.other_notes_btn .tabactive{
  background-color:#2DA2FB;
  color:white !important;
  border: none !important;
}

.other_notes_btn .tabactive:hover{
  background-color:#2DA2FB;
  color:white !important;
  border: none;
}

.searchField {
  width: 200px  !important;
}

.searchList {
  height: 300px !important;
  width: 300px !important;
  overflow: scroll !important;
}

.span_other{
background-color: #2DA2FB;
color: white;
padding: 10px 50px 10px 10px;
/* border-radius: 4px; */
font-weight: bold;
width: 22%;
}

.fixed_asset .search_box{
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
}


.fixed_asset .search_box p {
  margin-bottom: 0;
}

.fixed_asset .other_details_list ol{

  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
}

.fixed_asset .other_details_list{
  width: 50vw;
}

.other_details_table{
  display: inline-block;
  margin-top: 2rem;
  width: 40vw;
}

.share_capital_items .MuiTableCell-root{
border-bottom: none;
padding: 6px;

}

.share_capital .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 8px !important;
}

 .inputs_share{
  display: flex;
  flex-direction: row;
}

.inputs_share_container{
  display: flex;
  flex-direction: column;
}

.inputs_share .btn_box .input_elements{
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

}

.inputs_share .btn_box{
  margin-bottom: 10px;
}

.inputs_share .add_remove_button {
  display: flex;
}

.MuiStepLabel-label{
  font-size: 14px;
}

body{
  font-size: 14px !important;
}